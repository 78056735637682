import React from "react";
import { Heading } from "./common/Heading";
import { about } from "./data/dummydata";

import './assets/aboutComponent.css';

export const About = () => {    
  return (
    <section className='about container'>
      <Heading title='Notre entreprise' />

      <div className="about-container-grid">
        <div className="wrapper-content-image">
          <div>
            <p>{about[0].content}</p>
          </div>
          <div className="grid-img-fondateurs">
            <img
              src={about[1].cover}
              className="img-fluid beige-shadow"
              id="about-second-cover"
              alt='Mountain Solar Panels and Descriptive Text'
              data-aos='fade-down-right'
            />
          </div>
        </div>

        <div>
          <p>{about[1].content}</p>
        </div>
      </div>
    </section>
  );
};
